import PostAddIcon from "@mui/icons-material/PostAdd";
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
// constant
const icons = {
  PostAddIcon,
  PlaylistRemoveIcon,
  RequestQuoteIcon,
  CurrencyExchangeIcon,
};

// ==============================|| APPLICATION MENU ITEMS ||============================== //

const depositwithdraw = {
  id: "account-summary",
  title: "สรุปยอด",
  type: "group",
  children: [
    {
      id: "acc-deposit-summary",
      title: "สรุปยอดได้",
      type: "item",
      icon: icons.PostAddIcon,
      url: "/account-summary/deposit",
    },
    {
      id: "acc-withdraw-summary",
      title: "สรุปยอดเสีย",
      type: "item",
      icon: icons.PlaylistRemoveIcon,
      url: "/account-summary/withdraw",
    },
    {
      id: "acc-friendinvite-summary",
      title: "ประวัติจ่ายเชิญเพื่อน",
      type: "item",
      icon: icons.RequestQuoteIcon,
      url: "/account-summary/friend-invite",
    },
    {
      id: "acc-cashback-summary",
      title: "ประวัติจ่ายคืนยอดเสีย",
      type: "item",
      icon: icons.CurrencyExchangeIcon,
      url: "/account-summary/cashback",
    },
  ],
};

export default depositwithdraw;
