import EditNotificationsIcon from "@mui/icons-material/EditNotifications";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";

// constant
const icons = {
  EditNotificationsIcon,
  SupervisorAccountIcon,
  AccountBalanceIcon,
};

// ==============================|| APPLICATION MENU ITEMS ||============================== //

const promotion = {
  id: "api-group",
  title: "จัดการ API",
  type: "group",
  children: [
    {
      id: "agent-account",
      title: "บัญชี Agent",
      type: "item",
      icon: icons.SupervisorAccountIcon,
      url: "/agentinfo",
    },
    {
      id: "book-bank",
      title: "บัญชีธนาคาร",
      type: "item",
      icon: icons.AccountBalanceIcon,
      url: "/banksetting",
    },
  ],
};

export default promotion;
