import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
// constant
const icons = {
  ManageAccountsIcon,
  AdminPanelSettingsIcon,
};

// ==============================|| APPLICATION MENU ITEMS ||============================== //

const member = {
  id: "member-group",
  title: "จัดการสมาชิก",
  type: "group",
  children: [
    {
      id: "customers",
      title: "ลูกค้า",
      type: "item",
      icon: icons.ManageAccountsIcon,
      url: "/member/list",
    },
    {
      id: "admins",
      title: "แอดมิน",
      type: "item",
      icon: icons.AdminPanelSettingsIcon,
      url: "/admin/list",
    },
  ],
};

export default member;
