import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";

// member routing หน้าลูกค้า
const MemberCustomerList = Loadable(
  lazy(() => import("views/member/customer/list"))
);

const ApiSetting = Loadable(
  lazy(() => import("views/api-setting/agent-setting/agent-profile"))
);

const BankSetting = Loadable(
  lazy(() => import("views/api-setting/bank-setting/agent-profile"))
);

const WebSetting = Loadable(lazy(() => import("views/web-setting")));

const AdminList = Loadable(lazy(() => import("views/member/admin/list")));

const AdminDetail = Loadable(
  lazy(() => import("views/member/admin/account-profile"))
);

const MemberCustomerDetail = Loadable(
  lazy(() => import("views/member/customer/account-profile"))
);

const PromotionDetail = Loadable(
  lazy(() => import("views/promotion/promotion/promotion-detail"))
);

const WhellPrize = Loadable(
  lazy(() => import("views/promotion/wheelprize/wheel-prize"))
);

// dashboard routing
const DashboardDefault = Loadable(
  lazy(() => import("views/dashboard/Default"))
);
const DashboardAnalytics = Loadable(
  lazy(() => import("views/dashboard/Analytics"))
);

const WithdrawList = Loadable(
  lazy(() => import("views/account/withdraw/list"))
);

const DepositList = Loadable(lazy(() => import("views/account/deposit/list")));

const SummaryWithdraw = Loadable(
  lazy(() => import("views/account-summary/withdraw/list"))
);

const SummaryDeposit = Loadable(
  lazy(() => import("views/account-summary/deposit/list"))
);

const SummaryFriendInvite = Loadable(
  lazy(() => import("views/account-summary/friend-invite/list"))
);

const SummaryCashback = Loadable(
  lazy(() => import("views/account-summary/cashback/list"))
);

const CreditCodeList = Loadable(
  lazy(() => import("views/promotion/creditcode/list"))
);

const PromotionList = Loadable(
  lazy(() => import("views/promotion/promotion/list"))
);

const BannerList = Loadable(
  lazy(() => import("views/web-setting/banner/list"))
);

const BannerDetail = Loadable(
  lazy(() => import("views/web-setting/banner/banner-detail"))
);

// widget routing
const WidgetStatistics = Loadable(
  lazy(() => import("views/widget/Statistics"))
);
const WidgetData = Loadable(lazy(() => import("views/widget/Data")));
const WidgetChart = Loadable(lazy(() => import("views/widget/Chart")));

// basic ui-elements routing
const BasicUIAccordion = Loadable(
  lazy(() => import("views/ui-elements/basic/UIAccordion"))
);
const BasicUIAvatar = Loadable(
  lazy(() => import("views/ui-elements/basic/UIAvatar"))
);
const BasicUIBadges = Loadable(
  lazy(() => import("views/ui-elements/basic/UIBadges"))
);
const BasicUIBreadcrumb = Loadable(
  lazy(() => import("views/ui-elements/basic/UIBreadcrumb"))
);
const BasicUICards = Loadable(
  lazy(() => import("views/ui-elements/basic/UICards"))
);
const BasicUIChip = Loadable(
  lazy(() => import("views/ui-elements/basic/UIChip"))
);
const BasicUIList = Loadable(
  lazy(() => import("views/ui-elements/basic/UIList"))
);
const BasicUITabs = Loadable(
  lazy(() => import("views/ui-elements/basic/UITabs"))
);

// advance ui-elements routing
const AdvanceUIAlert = Loadable(
  lazy(() => import("views/ui-elements/advance/UIAlert"))
);
const AdvanceUIDialog = Loadable(
  lazy(() => import("views/ui-elements/advance/UIDialog"))
);
const AdvanceUIPagination = Loadable(
  lazy(() => import("views/ui-elements/advance/UIPagination"))
);
const AdvanceUIProgress = Loadable(
  lazy(() => import("views/ui-elements/advance/UIProgress"))
);
const AdvanceUIRating = Loadable(
  lazy(() => import("views/ui-elements/advance/UIRating"))
);
const AdvanceUISnackbar = Loadable(
  lazy(() => import("views/ui-elements/advance/UISnackbar"))
);
const AdvanceUISkeleton = Loadable(
  lazy(() => import("views/ui-elements/advance/UISkeleton"))
);
const AdvanceUISpeeddial = Loadable(
  lazy(() => import("views/ui-elements/advance/UISpeeddial"))
);
const AdvanceUITimeline = Loadable(
  lazy(() => import("views/ui-elements/advance/UITimeline"))
);
const AdvanceUIToggleButton = Loadable(
  lazy(() => import("views/ui-elements/advance/UIToggleButton"))
);
const AdvanceUITreeview = Loadable(
  lazy(() => import("views/ui-elements/advance/UITreeview"))
);

// pricing page routing
const PagesPrice1 = Loadable(lazy(() => import("views/pages/pricing/Price1")));
const PagesPrice2 = Loadable(lazy(() => import("views/pages/pricing/Price2")));

// utilities routing
const UtilsTypography = Loadable(
  lazy(() => import("views/utilities/Typography"))
);
const UtilsColor = Loadable(lazy(() => import("views/utilities/Color")));
const UtilsShadow = Loadable(lazy(() => import("views/utilities/Shadow")));
const UtilsMaterialIcons = Loadable(
  lazy(() => import("views/utilities/MaterialIcons"))
);
const UtilsTablerIcons = Loadable(
  lazy(() => import("views/utilities/TablerIcons"))
);
const UtilsAnimation = Loadable(
  lazy(() => import("views/utilities/Animation"))
);
const UtilsGrid = Loadable(lazy(() => import("views/utilities/Grid")));

// sample page routing
const SamplePage = Loadable(lazy(() => import("views/sample-page")));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "/wheeling",
      element: <WhellPrize />,
    },
    {
      path: "/web-setting",
      element: <WebSetting />,
    },
    {
      path: "/banner",
      element: <BannerList />,
    },
    {
      path: "/banner/detail/:id",
      element: <BannerDetail />,
    },
    {
      path: "/banksetting",
      element: <BankSetting />,
    },
    {
      path: "/agentinfo",
      element: <ApiSetting />,
    },
    {
      path: "/member/list",
      element: <MemberCustomerList />,
    },
    {
      path: "/admin/list",
      element: <AdminList />,
    },
    {
      path: "/admin/profile/:id",
      element: <AdminDetail />,
    },
    {
      path: "/member/profile/:id",
      element: <MemberCustomerDetail />,
    },
    {
      path: "/promotion/detail/:id",
      element: <PromotionDetail />,
    },
    {
      path: "/account/withdraw",
      element: <WithdrawList />,
    },
    {
      path: "/account/deposit",
      element: <DepositList />,
    },
    {
      path: "/account-summary/withdraw",
      element: <SummaryWithdraw />,
    },
    {
      path: "/account-summary/deposit",
      element: <SummaryDeposit />,
    },
    {
      path: "/account-summary/friend-invite",
      element: <SummaryFriendInvite />,
    },
    {
      path: "/account-summary/cashback",
      element: <SummaryCashback />,
    },
    {
      path: "/creditcode",
      element: <CreditCodeList />,
    },
    {
      path: "/promotion",
      element: <PromotionList />,
    },
    {
      path: "/widget/statistics",
      element: <WidgetStatistics />,
    },
    {
      path: "/widget/data",
      element: <WidgetData />,
    },
    {
      path: "/widget/chart",
      element: <WidgetChart />,
    },

    {
      path: "/basic/accordion",
      element: <BasicUIAccordion />,
    },
    {
      path: "/basic/avatar",
      element: <BasicUIAvatar />,
    },
    {
      path: "/basic/badges",
      element: <BasicUIBadges />,
    },
    {
      path: "/basic/breadcrumb",
      element: <BasicUIBreadcrumb />,
    },
    {
      path: "/basic/cards",
      element: <BasicUICards />,
    },
    {
      path: "/basic/chip",
      element: <BasicUIChip />,
    },
    {
      path: "/basic/list",
      element: <BasicUIList />,
    },
    {
      path: "/basic/tabs",
      element: <BasicUITabs />,
    },

    {
      path: "/advance/alert",
      element: <AdvanceUIAlert />,
    },
    {
      path: "/advance/dialog",
      element: <AdvanceUIDialog />,
    },
    {
      path: "/advance/pagination",
      element: <AdvanceUIPagination />,
    },
    {
      path: "/advance/progress",
      element: <AdvanceUIProgress />,
    },
    {
      path: "/advance/rating",
      element: <AdvanceUIRating />,
    },
    {
      path: "/advance/snackbar",
      element: <AdvanceUISnackbar />,
    },
    {
      path: "/advance/skeleton",
      element: <AdvanceUISkeleton />,
    },
    {
      path: "/advance/speeddial",
      element: <AdvanceUISpeeddial />,
    },
    {
      path: "/advance/timeline",
      element: <AdvanceUITimeline />,
    },
    {
      path: "/advance/toggle-button",
      element: <AdvanceUIToggleButton />,
    },
    {
      path: "/advance/treeview",
      element: <AdvanceUITreeview />,
    },

    {
      path: "/pages/price/price1",
      element: <PagesPrice1 />,
    },
    {
      path: "/pages/price/price2",
      element: <PagesPrice2 />,
    },

    {
      path: "/utils/util-typography",
      element: <UtilsTypography />,
    },
    {
      path: "/utils/util-color",
      element: <UtilsColor />,
    },
    {
      path: "/utils/util-shadow",
      element: <UtilsShadow />,
    },
    {
      path: "/icons/tabler-icons",
      element: <UtilsTablerIcons />,
    },
    {
      path: "/icons/material-icons",
      element: <UtilsMaterialIcons />,
    },
    {
      path: "/utils/util-animation",
      element: <UtilsAnimation />,
    },
    {
      path: "/utils/util-grid",
      element: <UtilsGrid />,
    },
    {
      path: "/sample-page",
      element: <SamplePage />,
    },
    {
      path: "/dashboard/default",
      element: <DashboardDefault />,
    },
    {
      path: "/dashboard/analytics",
      element: <DashboardAnalytics />,
    },
  ],
};

export default MainRoutes;
