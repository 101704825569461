// third-party
import { FormattedMessage } from "react-intl";

// assets
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";

// constant
const icons = {
  DisplaySettingsIcon,
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const setting = {
  id: "all-setting",
  title: "ตั้งค่า",
  type: "group",
  children: [
    {
      id: "web-setting",
      title: "ตั้งค่าเว็บไซต์",
      type: "item",
      url: "/web-setting",
      icon: icons.DisplaySettingsIcon,
      breadcrumbs: false,
    },
    {
      id: "banner-setting",
      title: "ตั้งค่าแบนเนอร์",
      type: "item",
      url: "/banner",
      icon: icons.DisplaySettingsIcon,
      breadcrumbs: false,
    },
  ],
};

export default setting;
