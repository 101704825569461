import { IconDashboard, IconDeviceAnalytics } from "@tabler/icons";

// constant
const icons = {
  IconDashboard,
  IconDeviceAnalytics,
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
  id: "dashboard",
  title: "หน้าหลัก",
  type: "group",
  children: [
    {
      id: "default",
      title: "ภาพรวม",
      type: "item",
      url: "/dashboard/default",
      icon: icons.IconDashboard,
      breadcrumbs: false,
    },
    //{
    //  id: "analytics",
    //  title: <FormattedMessage id="analytics" />,
    //  type: "item",
    //  url: "/dashboard/analytics",
    //  icon: icons.IconDeviceAnalytics,
    //  breadcrumbs: false,
    //},
  ],
};

export default dashboard;
