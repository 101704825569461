// material-ui
import React from "react";
import { useTheme } from "@mui/material/styles";
import { Avatar, Box } from "@mui/material";

// project imports
import LogoSection from "../LogoSection";
import MobileSection from "./MobileSection";
import ProfileSection from "./ProfileSection";
import MegaMenuSection from "./MegaMenuSection";
import NotificationSection from "./NotificationSection";
import { useDispatch, useSelector } from "store";
import { openDrawer } from "store/slices/menu";
import Chip from "@mui/material/Chip";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import IconButton from "@mui/material/IconButton";
// assets
import { IconMenu2 } from "@tabler/icons";
import io from "socket.io-client";
import Divider from "@mui/material/Divider";
import {
  getWithdrawHistory,
  getAgentBalance,
  getGSBData,
  getTMNData,
} from "store/slices/user";

// ==============================|| MAIN NAVBAR / HEADER ||============================== //
const socket = io("https://api.365hup.com");

const Header = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { drawerOpen } = useSelector((state) => state.menu);
  const { withdrawHistory, agentBalance, gsbData, tmnData } = useSelector(
    (state) => state.user
  );
  const [data, setData] = React.useState([]);
  const [balance, setBalance] = React.useState();
  const [gsbGetData, setGsbGetData] = React.useState([]);
  const [tmnGetData, setTmnGetData] = React.useState([]);

  React.useEffect(() => {
    setBalance(agentBalance);
  }, [agentBalance]);

  React.useEffect(() => {
    setGsbGetData(gsbData);
  }, [gsbData]);

  React.useEffect(() => {
    setTmnGetData(tmnData);
  }, [tmnData]);

  React.useEffect(() => {
    setData(withdrawHistory.filter((_trans) => _trans.status === "CHECKING"));
  }, [withdrawHistory]);

  React.useEffect(() => {
    dispatch(getAgentBalance());
    dispatch(getGSBData());
    dispatch(getTMNData());
  }, []);

  const handleClickRefresh = () => {
    dispatch(getAgentBalance());
    dispatch(getGSBData());
    dispatch(getTMNData());
  };

  React.useEffect(() => {
    socket.emit("get-client-count", "get");
    socket.on("withdraw-request-listen", (res) => {
      setTimeout(() => {
        dispatch(getWithdrawHistory());
        console.log("withdraw-request-listen");
      }, 1000);
    });
    socket.on("client-count", (res) => {
      console.log("client-count", res);
    });

    return () => {
      socket.off("withdraw-request-listen");
      socket.off("client-count");
    };
  }, []);

  React.useEffect(() => {
    dispatch(getWithdrawHistory());
  }, []);

  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: 228,
          display: "flex",
          [theme.breakpoints.down("md")]: {
            width: "auto",
          },
        }}
      >
        <Box
          component="span"
          sx={{ display: { xs: "none", md: "block" }, flexGrow: 1 }}
        >
          <LogoSection />
        </Box>
        <Avatar
          variant="rounded"
          sx={{
            ...theme.typography.commonAvatar,
            ...theme.typography.mediumAvatar,
            overflow: "hidden",
            transition: "all .2s ease-in-out",
            background:
              theme.palette.mode === "dark"
                ? theme.palette.dark.main
                : theme.palette.secondary.light,
            color:
              theme.palette.mode === "dark"
                ? theme.palette.secondary.main
                : theme.palette.secondary.dark,
            "&:hover": {
              background:
                theme.palette.mode === "dark"
                  ? theme.palette.secondary.main
                  : theme.palette.secondary.dark,
              color:
                theme.palette.mode === "dark"
                  ? theme.palette.secondary.light
                  : theme.palette.secondary.light,
            },
          }}
          onClick={() => dispatch(openDrawer(!drawerOpen))}
          color="inherit"
        >
          <IconMenu2 stroke={1.5} size="20px" />
        </Avatar>
      </Box>
      {/* header search 
      <SearchSection />
      */}
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />
      {/* mega-menu */}
      <IconButton aria-label="delete" onClick={handleClickRefresh}>
        <AutorenewIcon fontSize="small" color="primary" />
      </IconButton>
      <Divider orientation="vertical" variant="middle" flexItem sx={{ m: 2 }} />
      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <Chip
          label={tmnGetData && tmnGetData?.balance}
          color={tmnGetData?.balance > 20 ? "success" : "error"}
          size="large"
          sx={{ mr: 1 }}
        />
        ทรูมันนี่
      </Box>
      <Divider orientation="vertical" variant="middle" flexItem sx={{ m: 2 }} />
      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <Chip
          label={gsbGetData && gsbGetData?.account?.availableBalance}
          color={
            gsbGetData?.account?.availableBalance > 20 ? "success" : "error"
          }
          size="large"
          sx={{ mr: 1 }}
        />
        ธ.GSB
      </Box>
      <Divider orientation="vertical" variant="middle" flexItem sx={{ m: 2 }} />
      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <Chip
          label={balance && balance}
          color={balance > 1000 ? "success" : "error"}
          size="large"
          sx={{ mr: 1 }}
        />
        เครดิต
      </Box>
      <Divider orientation="vertical" variant="middle" flexItem sx={{ m: 2 }} />
      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <Chip
          label={data && data.length}
          color={data?.length > 0 ? "error" : "primary"}
          size="large"
          sx={{ mr: 1 }}
        />
        คำขอถอน
      </Box>
      <Divider orientation="vertical" variant="middle" flexItem sx={{ m: 2 }} />
      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <MegaMenuSection />
      </Box>
      {/* live customization & localization 
      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <LocalizationSection />
      </Box>*/}
      {/* notification & profile */}
      <NotificationSection />
      <ProfileSection />
      {/* mobile header */}
      <Box sx={{ display: { xs: "block", sm: "none" } }}>
        <MobileSection />
      </Box>
    </>
  );
};

export default Header;
